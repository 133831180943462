@import '../../theme/globals.css';

.load__body {
    background: var(--black);
    height: 100vh;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
}

.load__text {
    font-size: 25px;
    font-weight: 900;
    color: var(--white);
}

.load__animation {
    animation: rotate 1s infinite;  
    height: 50px;
    width: 50px;
}

.load__animation:before,
.load__animation:after {
    border-radius: 50%;
    content: '';
    display: block;
    height: 20px;  
    width: 20px;
}

.load__animation::before {
    animation: ball1 1s infinite;  
    background-color: var(--orange);
    box-shadow: 30px 0 0 var(--mainGrey);
    margin-bottom: 10px;
}

.load__animation:after {
    animation: ball2 1s infinite; 
    background-color: var(--lightGrey);
    box-shadow: 30px 0 0 var(--orange);
}

@keyframes rotate {
    0% { 
      -webkit-transform: rotate(0deg) scale(0.8); 
      -moz-transform: rotate(0deg) scale(0.8);
    }
    50% { 
      -webkit-transform: rotate(360deg) scale(1.2); 
      -moz-transform: rotate(360deg) scale(1.2);
    }
    100% { 
      -webkit-transform: rotate(720deg) scale(0.8); 
      -moz-transform: rotate(720deg) scale(0.8);
    }
  }
  
  @keyframes ball1 {
    0% {
      box-shadow: 30px 0 0 var(--orange);
    }
    50% {
      box-shadow: 0 0 0 var(--orange);
      margin-bottom: 0;
      -webkit-transform: translate(15px,15px);
      -moz-transform: translate(15px, 15px);
    }
    100% {
      box-shadow: 30px 0 0 var(--orange);
      margin-bottom: 10px;
    }
  }
  
  @keyframes ball2 {
    0% {
      box-shadow: 30px 0 0 #b4d4d4;
    }
    50% {
      box-shadow: 0 0 0 #b4d4d4;
      margin-top: -20px;
      -webkit-transform: translate(15px,15px);
      -moz-transform: translate(15px, 15px);
    }
    100% {
      box-shadow: 30px 0 0 #b4d4d4;
      margin-top: 0;
    }
  }