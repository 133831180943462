@import '../../theme/globals.css';

.logOutBtn {
    height: 25px;
    width: 100px;
    font-size: 16px;
    border-radius: 20px;
    margin-right: 25px;
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    outline: none;
    border: none;
    background-color: var(--lightGrey);
    color: var(--black);
}

.logOutBtn > span {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-right: 10px;
}

.logOutBtn:hover {
    transition: 0.5s ease-in-out;
    background-color: var(--orange);
    color: var(--black);
    box-shadow: 0px 0px 5px var(--blackFade);
}