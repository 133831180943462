@import '../../theme/globals.css';

/* Global Settings */
*:focus,
*:active {
  outline: none !important;
  -webkit-tap-highlight-color: transparent;
}

.navbar {
    background-color: transparent;
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    margin-top: 25px;
    z-index: 100;
    animation-name: fadeInNav;
    animation-iteration-count: 1;
    animation-timing-function: ease-out 0s 1;
    animation-duration: 2s;
}

@keyframes fadeInNav {
    0% {
        opacity: 0;
    }
    100% {
        opacity: 1;
    }
}

.navbar__left, 
.navbar__right {
    z-index: 100;
}

.menu__btn {
    font-size: 24px;
    margin-top: 25px;
    margin-right: 50px;
}

.menu__btn:hover {
    color: var(--orange);
    transition: 0.5s ease-in-out;
}

.navMenu__buttons {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    margin-top: 50px;
    margin-bottom: 75px;
    width: 100%;
}

.darkMode__btn,
.menuBars__close {
    font-size: 25px;
    font-weight: 900;
    color: var(--white);
    background: none;
    z-index: 100;
}

.menuBars__close {
    margin-right: 50px;
}

.darkMode__btn {
    margin-right: 25px;
}

.darkMode__btn:hover,
.menuBars__close:hover {
    transition: 0.5s ease-in-out;
    color: var(--orange);
}

/* Nav Menu */
.navMenu {
    background-color: var(--blackFade);
    display: flex;
    justify-content: center;
    position: fixed;
    top: 0;
    right: -100%;
    width: 25%;
    height: 100vh;
    transition: 850ms;
    box-shadow: 0px 0px 10px var(--dark);
    border-top-left-radius: 20px;
    border-bottom-left-radius: 20px;
    z-index: 100;
}

.navMenu.active {
    right: 0;
    transition: 350ms ease-in-out;
}

.navbar__top {
    height: 75vh;
}

/* Nav Items */
.navbar__top {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.navMenu__items {
    width: 100%;
}

.navItem {
    width: 100%;
    margin-top: 2rem;
    
}

.navItem > .is-active {
    background: linear-gradient(to left, var(--orange) 1%, var(--dark) 1%);
}

.navText {
    display: flex;
    align-items: center;
    text-transform: uppercase;
    list-style: none;
    text-decoration: none;
    color: #ffffff;
    font-size: 16px;
    letter-spacing: 5px;
    width: 100%;
    height: 48px;
    padding: 0 16px;
    transition: filter 0.4s ease-in-out;
}

.navText > span {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-left: 50px;
    margin-right: 10px;
}

.navText:hover {
    text-decoration: underline;
    text-decoration-thickness: 3px;
    text-underline-offset: 10px;
    text-decoration-color: var(--orange);
    transition: 0.5s ease-out;
}

.navText.is-active:hover {
    text-decoration: none;
}

@media (max-width: 450px) {
    .navMenu {
        width: 85%;
        height: 100vh;
        top: 0;
        border-radius: 0;
    }

    .navMenu.active {
        right: 0;
    }
}