@import '../../theme/globals.css';

.loginBtn {
    height: 48px;
    width: 150px;
    font-size: 20px;
    border-radius: 20px;
    outline: none;
    border: none;
}

.loginBtn:hover {
    background-color: var(--mainGrey);
    color: var(--black);
    box-shadow: 0px 0px 5px var(--blackFade);
}