@import url(https://fonts.googleapis.com/css2?family=Poppins&display=swap);
:root {
    /* Accents & Hovers */
    --blue: #3273dc;
    --lightBlue: #209cee;
    --orange: #f58820;
    --red: #ff3860;
    --lightRed: #ff3880;
    --green: #00d1b2;
    --lightGreen: #00d1a1;
    --black: #000000;
    --blackFade: #191919;
    --shadow: #a1a1a1;

    /* Main Greys */
    --mainGrey:     #d6d6d6;
    --lightGrey: #f2f2f2;

    /* Light and Dark Mode */
    --dark: #363636;
    --white: #ffffff;
}

* {
    box-sizing: border-box;
    -webkit-font-smoothing: antialiased;
}

.App {
    font-family: 'Poppins', sans-serif;
    position: relative;
    width: 100vw;
    height: 100vh;
    overflow-x: hidden;
}

body {
    margin: 0;
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
      'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
      sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
  }
  
  code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
      monospace;
  }

  a {
    text-decoration: none;
  }


/* Global Settings */
*:focus,
*:active {
  outline: none !important;
  -webkit-tap-highlight-color: transparent;
}

.navbar {
    background-color: transparent;
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    margin-top: 25px;
    z-index: 100;
    -webkit-animation-name: fadeInNav;
            animation-name: fadeInNav;
    -webkit-animation-iteration-count: 1;
            animation-iteration-count: 1;
    -webkit-animation-timing-function: ease-out 0s 1;
            animation-timing-function: ease-out 0s 1;
    -webkit-animation-duration: 2s;
            animation-duration: 2s;
}

@-webkit-keyframes fadeInNav {
    0% {
        opacity: 0;
    }
    100% {
        opacity: 1;
    }
}

@keyframes fadeInNav {
    0% {
        opacity: 0;
    }
    100% {
        opacity: 1;
    }
}

.navbar__left, 
.navbar__right {
    z-index: 100;
}

.menu__btn {
    font-size: 24px;
    margin-top: 25px;
    margin-right: 50px;
}

.menu__btn:hover {
    color: var(--orange);
    transition: 0.5s ease-in-out;
}

.navMenu__buttons {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    margin-top: 50px;
    margin-bottom: 75px;
    width: 100%;
}

.darkMode__btn,
.menuBars__close {
    font-size: 25px;
    font-weight: 900;
    color: var(--white);
    background: none;
    z-index: 100;
}

.menuBars__close {
    margin-right: 50px;
}

.darkMode__btn {
    margin-right: 25px;
}

.darkMode__btn:hover,
.menuBars__close:hover {
    transition: 0.5s ease-in-out;
    color: var(--orange);
}

/* Nav Menu */
.navMenu {
    background-color: var(--blackFade);
    display: flex;
    justify-content: center;
    position: fixed;
    top: 0;
    right: -100%;
    width: 25%;
    height: 100vh;
    transition: 850ms;
    box-shadow: 0px 0px 10px var(--dark);
    border-top-left-radius: 20px;
    border-bottom-left-radius: 20px;
    z-index: 100;
}

.navMenu.active {
    right: 0;
    transition: 350ms ease-in-out;
}

.navbar__top {
    height: 75vh;
}

/* Nav Items */
.navbar__top {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.navMenu__items {
    width: 100%;
}

.navItem {
    width: 100%;
    margin-top: 2rem;
    
}

.navItem > .is-active {
    background: linear-gradient(to left, var(--orange) 1%, var(--dark) 1%);
}

.navText {
    display: flex;
    align-items: center;
    text-transform: uppercase;
    list-style: none;
    text-decoration: none;
    color: #ffffff;
    font-size: 16px;
    letter-spacing: 5px;
    width: 100%;
    height: 48px;
    padding: 0 16px;
    transition: -webkit-filter 0.4s ease-in-out;
    transition: filter 0.4s ease-in-out;
    transition: filter 0.4s ease-in-out, -webkit-filter 0.4s ease-in-out;
}

.navText > span {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-left: 50px;
    margin-right: 10px;
}

.navText:hover {
    text-decoration: underline;
    text-decoration-thickness: 3px;
    text-underline-offset: 10px;
    -webkit-text-decoration-color: var(--orange);
            text-decoration-color: var(--orange);
    transition: 0.5s ease-out;
}

.navText.is-active:hover {
    text-decoration: none;
}

@media (max-width: 450px) {
    .navMenu {
        width: 85%;
        height: 100vh;
        top: 0;
        border-radius: 0;
    }

    .navMenu.active {
        right: 0;
    }
}
.loginBtn {
    height: 48px;
    width: 150px;
    font-size: 20px;
    border-radius: 20px;
    outline: none;
    border: none;
}

.loginBtn:hover {
    background-color: var(--mainGrey);
    color: var(--black);
    box-shadow: 0px 0px 5px var(--blackFade);
}
.logOutBtn {
    height: 25px;
    width: 100px;
    font-size: 16px;
    border-radius: 20px;
    margin-right: 25px;
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    outline: none;
    border: none;
    background-color: var(--lightGrey);
    color: var(--black);
}

.logOutBtn > span {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-right: 10px;
}

.logOutBtn:hover {
    transition: 0.5s ease-in-out;
    background-color: var(--orange);
    color: var(--black);
    box-shadow: 0px 0px 5px var(--blackFade);
}
.load__body {
    background: var(--black);
    height: 100vh;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
}

.load__text {
    font-size: 25px;
    font-weight: 900;
    color: var(--white);
}

.load__animation {
    -webkit-animation: rotate 1s infinite;
            animation: rotate 1s infinite;  
    height: 50px;
    width: 50px;
}

.load__animation:before,
.load__animation:after {
    border-radius: 50%;
    content: '';
    display: block;
    height: 20px;  
    width: 20px;
}

.load__animation::before {
    -webkit-animation: ball1 1s infinite;
            animation: ball1 1s infinite;  
    background-color: var(--orange);
    box-shadow: 30px 0 0 var(--mainGrey);
    margin-bottom: 10px;
}

.load__animation:after {
    -webkit-animation: ball2 1s infinite;
            animation: ball2 1s infinite; 
    background-color: var(--lightGrey);
    box-shadow: 30px 0 0 var(--orange);
}

@-webkit-keyframes rotate {
    0% { 
      -webkit-transform: rotate(0deg) scale(0.8); 
      -moz-transform: rotate(0deg) scale(0.8);
    }
    50% { 
      -webkit-transform: rotate(360deg) scale(1.2); 
      -moz-transform: rotate(360deg) scale(1.2);
    }
    100% { 
      -webkit-transform: rotate(720deg) scale(0.8); 
      -moz-transform: rotate(720deg) scale(0.8);
    }
  }

@keyframes rotate {
    0% { 
      -webkit-transform: rotate(0deg) scale(0.8); 
      -moz-transform: rotate(0deg) scale(0.8);
    }
    50% { 
      -webkit-transform: rotate(360deg) scale(1.2); 
      -moz-transform: rotate(360deg) scale(1.2);
    }
    100% { 
      -webkit-transform: rotate(720deg) scale(0.8); 
      -moz-transform: rotate(720deg) scale(0.8);
    }
  }
  
  @-webkit-keyframes ball1 {
    0% {
      box-shadow: 30px 0 0 var(--orange);
    }
    50% {
      box-shadow: 0 0 0 var(--orange);
      margin-bottom: 0;
      -webkit-transform: translate(15px,15px);
      -moz-transform: translate(15px, 15px);
    }
    100% {
      box-shadow: 30px 0 0 var(--orange);
      margin-bottom: 10px;
    }
  }
  
  @keyframes ball1 {
    0% {
      box-shadow: 30px 0 0 var(--orange);
    }
    50% {
      box-shadow: 0 0 0 var(--orange);
      margin-bottom: 0;
      -webkit-transform: translate(15px,15px);
      -moz-transform: translate(15px, 15px);
    }
    100% {
      box-shadow: 30px 0 0 var(--orange);
      margin-bottom: 10px;
    }
  }
  
  @-webkit-keyframes ball2 {
    0% {
      box-shadow: 30px 0 0 #b4d4d4;
    }
    50% {
      box-shadow: 0 0 0 #b4d4d4;
      margin-top: -20px;
      -webkit-transform: translate(15px,15px);
      -moz-transform: translate(15px, 15px);
    }
    100% {
      box-shadow: 30px 0 0 #b4d4d4;
      margin-top: 0;
    }
  }
  
  @keyframes ball2 {
    0% {
      box-shadow: 30px 0 0 #b4d4d4;
    }
    50% {
      box-shadow: 0 0 0 #b4d4d4;
      margin-top: -20px;
      -webkit-transform: translate(15px,15px);
      -moz-transform: translate(15px, 15px);
    }
    100% {
      box-shadow: 30px 0 0 #b4d4d4;
      margin-top: 0;
    }
  }
