@import url('https://fonts.googleapis.com/css2?family=Poppins&display=swap');

:root {
    /* Accents & Hovers */
    --blue: #3273dc;
    --lightBlue: #209cee;
    --orange: #f58820;
    --red: #ff3860;
    --lightRed: #ff3880;
    --green: #00d1b2;
    --lightGreen: #00d1a1;
    --black: #000000;
    --blackFade: #191919;
    --shadow: #a1a1a1;

    /* Main Greys */
    --mainGrey:     #d6d6d6;
    --lightGrey: #f2f2f2;

    /* Light and Dark Mode */
    --dark: #363636;
    --white: #ffffff;
}

* {
    box-sizing: border-box;
    -webkit-font-smoothing: antialiased;
}

.App {
    font-family: 'Poppins', sans-serif;
    position: relative;
    width: 100vw;
    height: 100vh;
    overflow-x: hidden;
}

body {
    margin: 0;
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
      'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
      sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
  }
  
  code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
      monospace;
  }

  a {
    text-decoration: none;
  }

